<template>
  <ul class="news-filter">
    <li class="news-filter__search">
      <input class="input" type="text" v-model="newsKey" :placeholder="`${ $store.getters.GET_LANG ? 'Поиск по новостям' : 'Search by news' }`">
      <button type="button" class="btn" @click="setNewsSearch()"> {{ $store.getters.GET_LANG ? 'Найти' : 'Search' }} </button>
    </li>
    <li class="news-filter__tabs">
      <p>
        <span v-for="(tab, index) in tabs" :class="{'active' : isActiveTab === index}" :key="`tab${index}`" @click="setTabNews(index)" v-show="$store.getters.GET_LANG || (!$store.getters.GET_LANG && tab.name !== 'LiveStream' && tab.name !== 'Announcements')">
          {{ tab.name }}
        </span>
      </p>
      <button type="button" class="btn btnLink" @click="showSelect = !showSelect">
        {{ $store.getters.GET_LANG ? 'Расширенный поиск' : 'Advanced search' }}
      </button>

      <button type="button" class="btn__icon btnBlock" @click="$store.dispatch('setView', false)" :class="{'active' : getListView}"></button>
      <button type="button" class="btn__icon btnList" @click="$store.dispatch('setView', true)" :class="{'active' : !getListView}"></button>
    </li>
    <li>
      <transition name="fade" mode="in-out">
        <div class="news-filter__filters" v-show="!!showSelect">
          <custom-select :key="`rubric${count}`"
                         class="select"
                         :list="getRubrics"
                         :multiple="true"
                         ref="rubricSelect"
                         @InputsContent="setRubrics($event)"
                         :placeholder="`${ $store.getters.GET_LANG ? 'Все рубрики' : 'All categories' }`"
                         :castom="`${ $store.getters.GET_LANG ? 'Все рубрики' : 'All categories' }`" />

          <custom-select :key="`year${count}`"
                         class="select"
                         :list="getListYears"
                         ref="yearSelect"
                         @InputsContent="setSelectValue($event, 'year')"
                         :placeholder="`${ $store.getters.GET_LANG ? 'Все года' : 'All years' }`"
                         :castom="`${ $store.getters.GET_LANG ? 'Все года' : 'All years' }`" />

          <tags-list-search :tags="getTagsList" @setTags="setTags"
            :placeholder="`${ $store.getters.GET_LANG ? 'Введите теги через слэш' : 'Enter tags separated by slash'}`"/>

          <button type="button" class="btn btnLink" :class="{'active' : getListView}" @click="resetFilters()">
            {{ $store.getters.GET_LANG ? 'Сбросить фильтры' : 'Reset the filters' }}
            </button>
        </div>
      </transition>
    </li>
  </ul>
</template>

<script>
import CustomSelect from '@/components/forms/CustomSelect'
import TagsListSearch from './TagsListSearch'

export default {
  name: 'NewsFilter',
  props: {
    currentTab: {
      type: String,
      required: false,
      default: 'All'
    }
  },

  data () {
    return {
      newsKey: '',
      rubric: null,
      year: null,
      tag: null,
      systemTagList: null,

      showSelect: false,
      count: 0,
      isActiveTab: 0,
      tabs: [
        { name: this.$store.getters.GET_LANG ? 'Все' : 'All', code: 'All' },
        { name: this.$store.getters.GET_LANG ? 'Новости' : 'News', code: 'News' },
        { name: this.$store.getters.GET_LANG ? 'Анонсы' : 'Announcements', code: 'Announcements' },
        { name: this.$store.getters.GET_LANG ? 'Комментарии' : 'Comment', code: 'Comment' },
        { name: this.$store.getters.GET_LANG ? 'Блог' : 'Blogs', code: 'Blogs' },
        { name: this.$store.getters.GET_LANG ? 'Медиа' : 'Media', code: 'Media' },
        { name: this.$store.getters.GET_LANG ? 'Трансляции' : 'LiveStream', code: 'LiveStream' }
      ]
    }
  },

  watch: {
    '$route' (to, from) {
      this.isActiveTab = this.tabs.findIndex((tab) => {
        return tab.code === this.$route.query.tab
      })
    },

    '$route.query.tab' () {
      this.resetFilters()
    },

    '$store.getters.GET_LANG' () {
      this.tabs = [
        { name: this.$store.getters.GET_LANG ? 'Все' : 'All', code: 'All' },
        { name: this.$store.getters.GET_LANG ? 'Новости' : 'News', code: 'News' },
        { name: this.$store.getters.GET_LANG ? 'Анонсы' : 'Announcements', code: 'Announcements' },
        { name: this.$store.getters.GET_LANG ? 'Комментарии' : 'Comment', code: 'Comment' },
        { name: this.$store.getters.GET_LANG ? 'Блог' : 'Blogs', code: 'Blogs' },
        { name: this.$store.getters.GET_LANG ? 'Медиа' : 'Media', code: 'Media' },
        { name: this.$store.getters.GET_LANG ? 'Трансляции' : 'LiveStream', code: 'LiveStream' }
      ]
    }
  },

  mounted () {
    this.$store.dispatch('getTags')
    this.$store.dispatch('getRubrics')

    if (this.$store.getters.getFilterTag) {
      this.tag = this.$store.getters.getFilterTag
      this.showSelect = true
    }

    if (this.$store.getters.getFilterRubric) {
      this.rubric = this.$store.getters.getFilterRubric
      this.$refs.rubricSelect.changeTitle(this.getRubricById(this.rubric).title, this.rubric)
      this.showSelect = true
    }

    this.tabs.forEach((item, index) => {
      if (item.code === this.currentTab) {
        this.isActiveTab = index
      }
    })

    this.isActiveTab = this.tabs.findIndex((tab) => {
      return tab.code === this.$route.query.tab
    })
  },

  beforeDestroy () {
    this.resetFilters()
  },

  methods: {
    getRubricById (id) {
      const rubric = this.getRubrics.filter((rubric) => rubric.id === id)

      if (rubric.length) {
        return rubric[0]
      } else {
        return null
      }
    },

    resetFilters () {
      this.$refs.rubricSelect.selectedList = []
      this.$refs.rubricSelect.titleIn = 'Все рубрики'

      this.$refs.yearSelect.selectedList = []
      this.$refs.yearSelect.titleIn = 'Все года'

      this.newsKey = ''
      this.rubric = null
      this.year = null
      this.tag = null
      this.systemTagList = null

      this.$store.commit('setFilterKey', this.newsKey)
      this.$store.commit('setFilterRubric', this.rubric)
      this.$store.commit('setFilterYear', this.year)
      this.$store.commit('setFilterTag', null)

      this.$store.commit('setFilterSystemTagList', this.systemTagList)
    },

    setSelectValue (value, key) {
      this[key] = value.id
    },

    setRubrics (value) {
      console.debug(value)
      if (value) {
        this.rubric = value.reduce((sum, current) => {
          return sum + current.id + ','
        }, '')
        this.rubric = this.rubric.substring(0, this.rubric.length - 1)
      } else {
        this.rubric = null
      }
    },

    setNewsSearch () {
      if (this.currentTab === 'All') {
        this.$router.push('/news?tab=News')
      }
      this.$store.commit('setPaginationOffset', 0)
      this.$store.commit('setFilterKey', this.newsKey)
      this.$store.commit('setFilterRubric', this.rubric)
      this.$store.commit('setFilterYear', this.year)
      this.$store.commit('setFilterTag', this.tag)
      this.$emit('getData', true)
    },

    setTabNews (index) {
      this.isActiveTab = index
      this.$emit('tab-changed', this.tabs[index].code)
    },

    addedTag (val) {
      this.tag.push(val)
    },

    setTags (tags) {
      this.tag = tags.join('/')
    }
  },

  computed: {
    getListView () {
      return this.$store.getters.getListView
    },

    getListYears () {
      const date = new Date().getFullYear()
      return [{ title: date, id: date }]
    },

    getFilters () {
      return this.$store.getters.getFilters
    },

    getRubrics () {
      return this.$store.getters.getRubrics
    },

    getTagsList () {
      if (this.$store.getters.getFilterTag) {
        return this.$store.getters.getFilterTag.split('/')
      } else {
        return []
      }
    }
  },

  components: {
    TagsListSearch,
    CustomSelect
  }
}
</script>

<style scoped lang="scss">
.news-filter {

  li {
    position: relative;
  }

  &__search {

    input {
      border: 1px solid #D5D6D9;
    }
    input::placeholder{
      color: #5E686C;
      opacity: 0.6;
    }

    button {
      border: none;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 0 1.625rem;

      background: #246CB7;
      border-radius: 3px;
      color: #FFFFFF;
    }
  }

  &__tabs {
    display: flex;
    padding: 2.5rem 0 0.875rem;
    border-bottom: .0625rem solid #D5D6D9;

    .btn {
      margin-left: auto;
    }
    p {
      display: flex;
      color: #003E78;

      span, .active {
        font-size: 0.8125rem;
        margin-right: 1.25rem;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        opacity: 0.6;

        cursor: pointer;
      }

      .active {
        font-weight: 600;
        color: #246CB7;
        opacity: 1;
      }

      .active::before {
        content: '';
        height: 100%;
        width: 100%;
        bottom: -0.9375rem;
        position: absolute;
        border-bottom: 0.125rem solid #246CB7;
      }
    }

  }

  &__filters {
    display: flex;
    margin-top: 1.25rem;
    align-items: center;

    .input, .select {
      width: 19.375rem;
      min-height: 2.45rem;
      margin-right: 0.625rem;
    }

    .btn {
      margin-left: auto;
    }
  }

  &__btn.active {
    opacity: 0.5;
  }

}
.fade-enter-active, .component-fade-leave-active {
  transition: all .35s;
}
.fade-enter, .component-fade-leave-to{
  opacity: 0;
  transform: translateY(-1rem);
}
@media screen and (max-width: 768px){
  .news-filter {
    &__tabs{
        border: none;
        // flex-wrap: wrap;
        padding: 1.25rem 0 0.875rem;
        overflow-x: auto;
        overflow-y: hidden;
        .btn {
          white-space: nowrap;
        }
    }
    &__filters {
      flex-wrap: wrap;

      .input, .select {
        width: 14rem;
        margin-right: 0.625rem;
        margin-bottom: 0.625rem;
      }
      .input {
        min-width: 50%;
        order: 4;
      }

      .btn {
        margin-left: auto;
        white-space: nowrap;
      }
    }
  }
  .btn__icon {
    padding: 1rem;
  }

}
@media screen and (max-width: 420px){
  .news-filter {
    &__tabs{
        border: none;
        flex-wrap: wrap;
        padding: 1.25rem 0 0.875rem;

        p {
          display: flex;
          padding-bottom: 0.93rem;
          border-bottom: 1px solid #eee;
          overflow-x: auto;
          overflow-y: hidden;
          margin-right: -1rem;
          margin-bottom: 1.375rem;
          .active::before{
            bottom: -0.86rem;
          }
        }

        .btn {
          margin-left: unset;
        }
        .btn__icon.btnBlock {
          margin-left: auto;
        }

    }

    &__filters {
      align-items: center;

      .input, .select {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0.625rem;
      }

      .btn {
        margin-left: auto;
        order: 4;
      }
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .btnLink {
    background: none;
  }
}

</style>
