<template>
    <router-link class="main-content" :to="{path: '/news/' + card.id}">
      <figure :style="`background: url('${card.preview_photo}') center / cover`"></figure>
      <article>
        <span class="main-content__rubric">{{ $store.getters.GET_LANG ? card.rubric_model.title : card.rubric_model.title_eng }}</span>
        <span class="main-content__date">{{ getFormattedDate(card.date_time) }}</span>
        <p class="main-content__title">{{ $store.getters.GET_LANG ? card.title : card.title_eng }}</p>
        <p class="main-content__description">{{ $store.getters.GET_LANG ?  card.description :  card.description_eng }}</p>
      </article>
    </router-link>

</template>

<script>
import formatDate from '@/mixins/formatDate'

export default {
  name: 'NewsMain',
  props: {
    card: {
      type: Object
    }
  },
  mixins: [
    formatDate
  ]
}
</script>

<style scoped lang="scss">

  .main-content {
    width: 100%;
    display: flex;
    justify-content: space-between;

    figure {
      width: 33.75rem;
      height: 21.875rem;
      border-radius: 0.5rem;
    }

    article {
      width: 20rem;
    }

    &__date {
      font-size: 0.875rem;
      margin-left: 1rem;
      color: #5E686C;
    }

    &__rubric {
      background: #EEF4FA;
      border-radius: 3px;
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 123%;
      padding: .125rem .625rem;

      text-transform: uppercase;

      color: #003E78;
      opacity: 0.8;
    }

    &__title {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 133%;
      color: #1F3245;

      margin: 1.125rem 0;
    }

    &__description {
      font-size: 0.875rem;
      line-height: 157%;
      color: rgba(23, 23, 23, 0.8);
    }
  }
  @media screen and (max-width: 768px){
    .main-content {

      figure {
        width: 49%;
        height: 15rem;
      }
      article {
        width: 49%;
      }
    }
  }
  @media screen and (max-width: 420px){
    .main-content {
      flex-direction: column;
      figure {
        width: 100%;
        height: 12.5rem;
      }
      article {
        width: 100%;
        margin-top: 1.375rem;
      }
    }
  }

</style>

<style lang="scss">

  .slider-stream {
    overflow: hidden;
    width: 55.8rem;
    height: 100%;
    border-radius: 0.5rem;

    &.hooper {
        height: 100%;
    }

    .hooper-list {
        .hooper-track{
            display: flex;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
            margin-left: -0.6rem;
        }
    }
  }

  .hooper-sr-only {
    display: none;
  }

  @media screen and (max-width: 768px){
    .slider-stream {
      width: 45rem;
    }
  }
  @media screen and (max-width: 420px){
    .slider-stream {
      width: 18rem;
    }
  }

</style>
